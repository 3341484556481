<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="templateForm" label-width="75px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
          <DatePeriodPicker label="编辑时间：" start-prop="update_start_time" :start-time.sync="form.update_start_time"
                            end-prop="update_end_time" :end-time.sync="form.update_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

  // import {aElementDownload} from "@/base/utils/tool";

  export default {
    name: "ListFilter",
    components: {
      LayoutFilter,
      DatePeriodPicker,
    },
    props: {
      uploadFilter: {
        type: Function,
        required: true,
      },
      filterForm: {
        type: Object,
      },
    },
    data() {
      return {
        rules: {},
      };
    },
    computed: {
      form: {
        get() {
          return this.filterForm;
        },
        set(val) {
        },
      },
    },
    created() {

    },
    methods: {
      // onExport() {
      //   const postData = {...this.form};
      //   return documentArticle(postData)
      //     .then((blob) => {
      //       aElementDownload(blob, "文章列表.xlsx");
      //     })
      //     .catch(() => {
      //     });
      // },
      //重置表单
      resetForm() {
        this.$refs["templateForm"].resetFields();
      },
      //筛选
      ok() {
        this.uploadFilter(this.form);
      },
    },
  };
</script>

