<template>
  <div class="template-list">
    <el-button class="top-btn" style="margin-bottom: 20px;" size="small" type="primary" @click="toDetail()">新增</el-button>
    <list-filter :filterForm="myForm" :uploadFilter="callbackForm"></list-filter>
    <list-tabs v-model="myForm.tab" :isLoading="loading" :tabs="tabList"
               @tab-click="handleChangeTab">
    </list-tabs>
    <div v-loading="loading">
      <div class="list-top">
        <el-button v-if="myForm.tab === 'deleted'" size="small" type="success" @click="handlePutBack(-1)">还原</el-button>
        <el-button size="small" type="danger" @click="deleteItem(-1)">删除</el-button>
      </div>
      <el-table style="width: 100%; margin-bottom: 50px;margin-top: 20px" class="thead-light" stripe
                :data="tbody" @selection-change="handleSelection" @sort-change="sortChange">
        <!-- 多选 -->
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- 排序 -->
        <el-table-column label="排序" min-width="100">
          <template slot-scope="{row}">
            <popover-input-edit v-model="row.sort" autofocus @confirm="updateSort(row)"></popover-input-edit>
          </template>
        </el-table-column>
        <!-- 普通文本 -->
        <el-table-column v-for="(item, index) in thead" :key="item.prop" show-overflow-tooltip
                         :prop="item.prop" :label="item.label" :min-width="item.minWidth?item.minWidth:''"
                         :width="item.width" :sortable="item.sortable">
          <template slot-scope="scope">
            <p>{{scope.row[item.prop] | placeholder}}</p>
          </template>
        </el-table-column>
        <!-- 显示 -->
        <el-table-column label="显示">
          <template slot-scope="{row}">
            <el-checkbox v-model="row.status" :true-label="1" :false-label="0" @change="updateStatus(row)"></el-checkbox>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" type="text"
                         v-if="myForm.tab !== 'deleted'"
                         @click="toDetail(scope.row.id)">编辑
              </el-button>
              <el-button size="small" type="text"
                         v-if="myForm.tab === 'deleted'"
                         @click="handlePutBack(scope.row.id)">还原
              </el-button>
              <el-button size="small" type="text"
                         @click="deleteItem(scope.row.id)">删除
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>
    </div>
  </div>
</template>

<script>
  import ListFilter from "../../components/ArticleTemplate/ListFilter";
  import {
    getList,
    putBack,
    softDel,
    trueDel,
    updateTemplateSort,
    updateTemplateStatus
  } from "../../api/article-template";
  import Pagination from "../../../../base/components/Default/Pagination";
  import PopoverInputEdit from "../../../../base/components/Popover/PopoverInputEdit";

  export default {
    components: {PopoverInputEdit, Pagination, ListFilter},
    data() {
      return {
        loading: true,
        myForm: {
          page: 1,
          page_size: 15,
          order_by: "sort",
          is_desc: 0,
          update_start_time: -1,
          update_end_time: -1,
          keyword: "",
          tab: "normal"
        },
        tabList: [
          {name: "normal", label: "全部"},
          {name: "deleted", label: "回收站"}
        ],
        filters: {
          normal: null,
          deleted: null
        },
        pageData: {},
        thead: [
          { label: "名称", prop: "name", minWidth: 200 },
          { label: "编辑时间", prop: "update_time", width: 200, sortable: true },
        ],
        tbody: [],
        idList: [],
      }
    },
    created() {
      this.getList(this.myForm);
    },
    methods: {
      toDetail(e) {
        this.$router.push({name: "CustomArticleTmplDetail", params: {id: e || 0}})
      },
      sortChange(e) {
        this.myForm.order_by = e.prop;
        if (e.order === "ascending") {
          this.myForm.is_desc = 0;
        } else if (e.order === "descending") {
          this.myForm.is_desc = 1;
        }
        this.getList(this.myForm)
      },
      handlePutBack(id) {
        if (id === -1 && !this.idList.length) {
          return this.$message.info('请选择数据项')
        }
        this.loading = true;
        let api = putBack(id === -1 ? this.idList : [id]);
        api.then(res => {
          this.$message.success(res.msg);
          this.getList(this.myForm);
        }).catch(err => {
          this.loading = false;
        })
      },
      deleteItem(id) {
        if (id === -1 && !this.idList.length) {
          return this.$message.info('请选择数据项')
        }
        this.$confirm(`确定删除这${id !== -1 ? '个' : '些'}模板吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(res => {
          this.loading = true;
          let api;
          if (this.myForm.tab === 'deleted') {
            api = trueDel(id === -1 ? this.idList : [id]);
          } else {
            api = softDel(id === -1 ? this.idList : [id]);
          }
          api.then(res => {
            this.$message.success(res.msg);
            this.getList(this.myForm);
          }).catch(err => {
            this.loading = false;
          })
        }).catch(err => {
          console.log('操作已取消')
        })
      },
      //选中项
      handleSelection(data) {
        let arr = [];
        data.forEach((item, index) => {
          arr.push(item.id);
        });
        this.idList = arr;
      },
      handleChangeTab(e) {
        if (!this.filters[e.name]) {
          this.myForm = {
            page_size: this.pageData.per_page,
            tab: e.name,
            update_start_time: -1,
            update_end_time: -1,
            keyword: "",
            order_by: "sort",
            is_desc: 0,
          };
        } else {
          this.myForm = this.filters[e.name];
        }
        this.getList(this.myForm);
      },
      getList(requestData) {
        this.tbody = [];
        this.pageData = {};
        this.loading = true;
        getList(requestData)
          .then((res) => {
            this.tbody = res.data.data;
            this.pageData = res.data;
            this.filters[res.data.tab] = {
              ...this.myForm,
              page_size: res.data.per_page,
            };
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      callbackForm(e) {
        this.myForm = e;
        this.getList(this.myForm)
      },
      //页面切换
      changPage(data) {
        const pageData = { ...this.myForm, ...data };
        this.myForm.page_size = data.page_size;
        this.getList(pageData);
      },
      updateSort(e) {
        this.loading = true;
        updateTemplateSort({
          id: e.id,
          sort: e.sort
        }).then(res => {
          this.$message.success(res.msg);
          this.getList(this.myForm); // 刷新列表
        }).catch(err => {
          this.loading = false;
        })
      },
      updateStatus(e) {
        this.loading = true;
        updateTemplateStatus({
          id: e.id,
          status: e.status
        }).then(res => {
          this.$message.success(res.msg);
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.template-list {

}
</style>
